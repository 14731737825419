<template>
  you are in command
</template>

<script>

export default {
  name: "CommandCenter",
  computed: {
    myTitle() {
    },
  },
};
</script>
