// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Starjout";
  src: url("https://deployatron-tp.devlovin.com/Starjout.ttf") format("truetype");
}
@font-face {
  font-family: "Starjhol";
  src: url("https://deployatron-tp.devlovin.com/Starjhol.ttf") format("truetype");
}
@font-face {
  font-family: "Starjedi";
  src: url("https://deployatron-tp.devlovin.com/Starjedi.ttf") format("truetype");
}
@font-face {
  font-family: TradeGothic;
  src: url("https://deployatron-tp.devlovin.com/Trade-Gothic-Bold.otf") format("opentype");
}
body {
  background: black;
}

* {
  font-family: TradeGothic;
  color: #969696;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/styles/base.scss"],"names":[],"mappings":"AACA;EACE,uBAAA;EACA,+EAAA;AAAF;AAEA;EACE,uBAAA;EACA,+EAAA;AAAF;AAEA;EACE,uBAAA;EACA,+EAAA;AAAF;AAEA;EACE,wBAAA;EACA,wFAAA;AAAF;AAIA;EACE,iBAAA;AAFF;;AAIA;EACE,wBAAA;EACA,cAAA;AADF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n@font-face {\n  font-family: 'Starjout';\n  src: url('https://deployatron-tp.devlovin.com/Starjout.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'Starjhol';\n  src: url('https://deployatron-tp.devlovin.com/Starjhol.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'Starjedi';\n  src: url('https://deployatron-tp.devlovin.com/Starjedi.ttf') format('truetype');\n}\n@font-face {\n  font-family: TradeGothic;\n  src: url(\"https://deployatron-tp.devlovin.com/Trade-Gothic-Bold.otf\") format(\"opentype\");\n}\n\n\nbody {\n  background: black;\n}\n* {\n  font-family: TradeGothic;\n  color: #969696;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
