<template>
  <CommandCenter v-if="youAreInCommand" />
  <WaitingRoom v-else />
</template>

<script>
import { useGeneralStore } from "@/stores/general-store";
import { mapState } from "pinia";
import CommandCenter from "@/pages/CommandCenter";
import WaitingRoom from "@/pages/WaitingRoom";

export default {
  name: "Home",
  components: { CommandCenter, WaitingRoom },
  computed: {
    ...mapState(useGeneralStore, [
      "queue",
      "myUserId",
    ]),
    youAreInCommand() {
      return this.queue.length > 0 && this.queue[0].userId == this.myUserId;
    },
  },
};
</script>
