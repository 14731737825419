<template>
  <iRow>
    <iColumn>
      <iHeading>
          Tristen is in command
</iHeading>

<br />
          Deploying to Staging
          <ul>
            <li>
              commit 9ba27f0c (HEAD)
            </li>
            <li>
              started 2m ago (4:09pm 32s)
            </li>
            <li>
              estimated 87s remaining
            </li>
            <li>
          Merged PRs
          <ul>
            <li>
              9ba27f0c merged 10s ago - drag and drop improvements for story editor
            </li>
            <li>
              763ab78f merged 5m ago - drag and drop improvments for dynamic component builder layers sidebar
            </li>
          </ul>
            </li>
          </ul>
    </iColumn>
  </iRow>
</template>

<script>
export default {
  name: "CurrentActivity",
  props: {
  },
};
</script>
