<template>
<iHeading>
  queue
</iHeading>
</template>

<script>
export default {
  name: "Queue",
  props: {
  },
};
</script>
