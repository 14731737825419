<template>
  <Starfield>
    <iColumn>
      <iRow justify-content="space-between">
        <iRow width="hug">
          <iHeading variant="accent" @click="">
            R2-D2
          </iHeading>
          <iHeading variant="subtle">
            /
          </iHeading>
          <iHeading variant="subtle">
            {{ title }}
          </iHeading>
        </iRow>
        <iRow width="hug">
          <iHeading variant="accent" @click="">
            force quit
          </iHeading>
        </iRow>
      </iRow>
      <iRow width="hug">
        <iColumn>
          <slot />
        </iColumn>
      </iRow>
    </iColumn>
  </Starfield>
</template>

<script>
import Starfield from "@/components/Starfield";

export default {
  name: "PageWrapper",
  components: { Starfield },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
