<template>
<iHeading>
  Monitoring
</iHeading>
</template>

<script>
export default {
  name: "Monitoring",
  props: {
  },
};
</script>
